@font-face {
  font-family: 'Kanit';
  src: local('Kanit'), url(/public/fonts/Kanit-Regular.ttf) format('truetype');
}

:root {
  --bg-color: #f5f9ff;
  --dark-bg-color: #222831;
  --primary-color: #f0da54;
  --secondary-color: #f05454;
  --tertiary-color: #5eef56;
}

/* GENERALS */
.mb-7,
.my-7 {
  margin-bottom: 5rem !important;
}

.mt-7,
.my-7 {
  margin-top: 5rem !important;
}

html {
  scroll-behavior: smooth;
  width: 100vw;

}

body {
  background-color: var(--bg-color);
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  box-sizing: border-box;
  /* -moz-osx-font-smoothing: grayscale; */
}

body::-webkit-scrollbar {
  display: none;
}

p {
  font-size: 1.1rem;
  font-weight: 400;
}

ul li {
  font-weight: 700;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--primary-color);
  font-weight: lighter;
  scroll-behavior: smooth;
}

a:hover {
  color: var(--secondary-color);
  text-decoration: none;
}

.force-maxwidth {
  max-width: 100vw;
}

.btn-primary {
  font-weight: 700;
  padding: 1rem;
  color: var(--dark-bg-color);
  background-color: var(--primary-color);
  border: none;
}

.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:hover {
  color: var(--dark-bg-color);
  background-color: var(--primary-color);
  filter: brightness(0.8);
}

.bg-dark {
  background-color: var(--dark-bg-color) !important;
}

.framed {
  background-color: var(--dark-bg-color);
  color: var(--bg-color);
  padding: 2em;
  font-size: 120%;
  font-family: 'Kanit';
  border-radius: 10px;
  text-align: center;
}

#fullpage {
  position: fixed;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  top: 0;
  left: 0;
  max-width: 100vw;
  max-height: 100vh;
  background-color: rgba(34, 40, 49, .75);
  transition: opacity 1s ease-in-out;
}

#fullpage-img {
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  background-position: center center;
}

.navigation {
  z-index: 5;
}

/* .navigation:after { */
/* content: ' ';
  display: block;
  border-top: 7vh solid var(--dark-bg-color);
  border-right: 100vw solid transparent;
  position: absolute; */
/* bottom: 1px; */
/* } */
.navbar-dark {
  height: 80px;
}

.navbar-dark .navbar-brand {
  display: flex;
  justify-content: center;
  color: var(--primary-color);
  position: relative;
  top: 0;
  opacity: 1;
  transition: top 1s ease-in-out, opacity 1s ease-in-out;
}

.navbar-dark .navbar-brand.active {
  /* visibility: hidden; */
  position: relative;
  top: -100px;
  opacity: 1;
}

.logo-text {
  color: var(--primary-color);
  font-family: "Kanit";
  font-weight: bolder;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 25px;
  letter-spacing: 1px;
  line-height: 20px;
  margin-left: .6em;
  margin-top: .2em;
}

.logo-text span:nth-child(2) {
  color: var(--secondary-color);
  font-weight: normal;
}

.animated-logo-wrapper {
  /* height: 56px; */
  width: 51px;
}

.animated-logo-wrapper svg {
  max-height: 56px;
  /* max-width: 51px; */
  position: absolute;
}

.animated-logo-wrapper svg .st0 {
  fill: #F0DA54;
  stroke: #F0DA54;
  stroke-width: 4;
}

.animated-logo-wrapper svg .st1 {
  fill: #F05454;
}

.animated-logo-wrapper svg .st2 {
  fill: none;
  stroke: #F0DA54;
  stroke-width: 14;
  stroke-miterlimit: 10;
}

.animated-logo-wrapper svg .st3 {
  fill: #F05454;
}

.navbar-brand:hover #logo-ball {
  animation: bounceDown 1.6s linear;
}

.navbar-brand:hover #logo-flipper {
  transform-origin: 45px 50px;
  animation: flip 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.active,
.dot:hover {
  color: var(--secondary-color);
}

.navbar-dark .navbar-nav {
  font-family: 'Kanit';
  border-bottom: var(--primary-color) 2px solid;
  /* height: 43px; */
}

.navbar-dark .navbar-nav #nav-ball {
  color: var(--bg-color);
  background-color: var(--primary-color);
  content: " ";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  opacity: 1;
  top: 50px;
  transition: left 2s cubic-bezier(0.68, -0.6, 0.32, 1.6), opacity 2s ease-in-out;
  /* cubic-bezier(0.34, 1.56, 0.64, 1), */
}

@media (max-width: 768px) {
  .navbar-dark .navbar-nav {
    padding-left: 20px;
  }

  .navbar-dark .navbar-nav #nav-ball {
    display: none;
  }
}

/* .navbar-dark .navbar-nav .nav-link.active:after {
  opacity: 1;
} */

.navbar-dark .navbar-nav .nav-link {
  color: var(--bg-color);
}

.navbar-dark .navbar-nav .nav-link.active {
  color: var(--primary-color);
  padding-bottom: 0;
}

@media (max-width: 767.98px) {
  .navbar-expand-md>.container {
    z-index: 5;
    background-color: var(--dark-bg-color);
  }
}

.intro-p {
  text-align: center;
  font-size: 1.5em;
}

.bf-button {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

button {
  background-color: var(--primary-color);
  color: var(--dark-bg-color);
  padding: 1em;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}

.bf-button a {
  background-color: var(--primary-color);
  color: var(--dark-bg-color);
  padding: 1em;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
}

.bf-button:before {
  content: ' ';
  display: block;
  width: 30px;
  height: 0;
  border-bottom: 56px solid var(--primary-color);
  border-left: 30px solid transparent;
  transition: border .25s ease-in;
  position: relative;
  left: 1px;
}

.bf-button:after {
  content: ' ';
  display: block;
  width: 30px;
  height: 0;
  border-top: 56px solid var(--primary-color);
  border-right: 30px solid transparent;
  transition: border .25s ease-in;
  position: relative;
  right: 1px;
}

.bf-button:hover:before {
  border-bottom: 56px solid var(--primary-color);
  border-left: 20px solid transparent;
}

.bf-button:hover:after {
  border-top: 56px solid var(--primary-color);
  border-right: 20px solid transparent;
}

.bf-big-title {
  font-weight: bolder;
  font-style: italic;
  font-size: 3em;
  color: var(--dark-bg-color);
}

.bf-big-title:after,
.bf-big-title:before {
  display: inline-block;
  content: " ";
  margin: 0 .5em;
  margin-bottom: .1em;
  width: 20px;
  height: 20px;
  background-color: var(--dark-bg-color);
  border-radius: 50%;
}

/* FOOTER */
.footer {
  color: var(--bg-color);
}

.footer:before {
  content: ' ';
  display: block;
  border-bottom: 5vh solid var(--dark-bg-color);
  border-right: 100vw solid transparent;
  position: relative;
  top: 1px;
}

/* INTRO MATTER */
.home #intro-container {
  background-color: var(--dark-bg-color);
  color: var(--bg-color);
  min-height: 75vh;
  padding-top: 5rem;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 7em;
  z-index: 2;
}

.home #intro-container:after {
  content: ' ';
  display: block;
  border-top: 8vh solid var(--dark-bg-color);
  border-right: 100vw solid transparent;
  position: relative;
  bottom: -7.90vh;
}

.home h2 {
  margin-bottom: 1.5rem;
}

.home p {
  font-size: 1.3em;
  ;
}

.page {
  min-height: calc(100vh - 184px);
}

#bf-pinball {
  background-image: url(/public/img/bf-pinball.svg);
  background-size: contain;
  background-repeat: no-repeat;
  /* margin: 1em 2em 1em 8em; */
  margin: 1em auto;
  max-width: 480px;
  height: 600px;
}

@media (max-width: 510px) {
  #bf-pinball {
    max-width: 300px;
  }
}

#matter {
  transform: rotate(-30deg) skewX(-30deg) scale(0.31);
  left: -178px;
  top: -61px;
  position: relative;
  overflow: hidden;
  width: 680px;
  height: 480px;
}

@media (max-width: 510px) {
  #matter {
    transform: rotate(-30deg) skewX(-30deg) scale(0.2);
    left: -78%;
    top: -20.5%;
    /* display: none; */
  }
}

.bf-pinball-button {
  transform: rotate(-30deg) skewX(-30deg);
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  border: 2px solid var(--dark-bg-color);
  position: relative;
  left: 350px;
  top: -40px;
}

.bf-pinball-button:nth-child(2) {
  left: 440px;
  top: -80px;
}

@media (max-width: 510px) {
  .bf-pinball-button {
    left: 230px;
    top: -202px;
  }

  .bf-pinball-button:nth-child(2) {
    left: 280px;
    top: -220px;
  }
}


.text-to-canvas h1,
.text-to-canvas h2 {
  margin: 0 auto;
  font-family: "Kanit", "Source Code Pro", monospace;
  /* transform: rotate(-30deg) skewX(-30deg); */
  font-weight: bolder;
  font-size: 7rem;
  line-height: 2.1rem;
  z-index: 3;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

@media (max-width: 510px) {

  .text-to-canvas h1,
  .text-to-canvas h2 {
    font-size: 25vw;
  }
}

.text-to-canvas h1 {
  color: var(--secondary-color);
  animation: text-flicker-secondary 12s linear infinite;
}

.text-to-canvas h2 {
  color: var(--primary-color);
  animation: text-flicker-primary 9s linear infinite;
}

.text-to-canvas h1 span,
.text-to-canvas h2 span {
  position: absolute;
  display: block;
}

.text-to-canvas h2 span.off {
  animation: letter-flicker-primary 8s linear infinite;
}

.text-to-canvas h1 span.off {
  animation: letter-flicker-secondary 6s linear infinite;
}


/* PINBALL LIST */
ul.bf-pinball-list {
  list-style: none;
  padding-left: 0;
}

li.bf-list-element {
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex: 1 1;
  padding: 0.75rem;
  cursor: pointer;
  color: var(--dark-bg-color);
  background-color: var(--bg-color);
  border-radius: 8px;
  border: 2px solid var(--bg-color);
  transition: all 0.5s ease-in;
  max-height: 85px;
  overflow: hidden;
  white-space: nowrap;
}

li.bf-list-element .bf-list-item-content {
  pointer-events: none;
  justify-self: flex-start;
}

li.bf-list-element .bf-item-soon {
  display: flex;
  justify-self: flex-end;
  /* float: right; */
  background: var(--primary-color);
  color: var(--dark-bg-color);
  font-size: .7em;
  font-weight: 500;
  align-self: center;
  /* margin-left: 2em; */
  padding: .5em 6em;
  /* border-radius: 15px; */
  transition: all 0.5s ease-in;
  transform: rotate(35deg);
}

/* li.bf-list-element:before {
  display: block;
  content: " ";
  width: 20px;
  height: 50px;
  background: var(--secondary-color);
  transition: all 0.5s ease-in;
} */
/* li.bf-list-element.bf-item-available:before {
  background: var(--tertiary-color);
} */
li.bf-list-element h5 {
  margin: 0;
  padding-left: 0.8rem;
  font-size: 1.1rem;
  transition: all 0.25s ease-in;
}

li.bf-list-element small {
  padding-left: 1.2rem;
  font-size: 1rem;
  font-weight: light;
  transition: all 0.25s ease-in;
}

/* ACTIVE PRODUCT */
li.bf-list-element.active {
  color: var(--bg-color);
  background-color: var(--dark-bg-color);
}

li.bf-list-element.active:before {
  width: 40px;
  height: 60px;
}

li.bf-list-element.active h5 {
  font-size: 1.5rem;
}

li.bf-list-element.active small {
  font-size: 1.2rem;
}

.bf-item-soon {
  font-size: medium;
  font-style: italic;
  color: var(--tertiary-color);
  display: inline-block;
}

/* Slideshow */
.zoomable {
  cursor: pointer;
}

.slideshow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  height: 720px;
  position: relative;
  background-color: white;
  margin-bottom: 2rem;
}

.slides {
  display: none;
}

.slides img {
  max-height: 720px;
}

.exit {
  cursor: pointer;
  position: absolute;
  top: 40px;
  right: 2vw;
  width: auto;
  margin-top: -45px;
  padding: 16px;
  background-color: var(--dark-bg-color);
  color: var(--primary-color);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 0 0px 10px 10px;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -32px;
  padding: 16px;
  background-color: var(--dark-bg-color);
  color: var(--primary-color);
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  user-select: none;
}

.prev {
  left: 0px;
  border-radius: 0 10px 10px 0;
}

.next {
  right: 0px;
  border-radius: 10px 0 0 10px;
}

.prev:hover,
.next:hover {
  background-color: var(--primary-color);
  color: var(--dark-bg-color);
}

.dot {
  cursor: pointer;
  height: 5px;
  width: 5px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

.fade:not(.show) {
  opacity: 1;
}

/* PRICE TAG */
.bf-tarifs {
  margin-bottom: 2rem;
}

.bf-price-tag {
  border: var(--dark-bg-color) solid 2px;
  /* border-radius: 6px; */
  padding: 0;
}

.bf-price-tag-header {
  color: var(--bg-color);
  background-color: var(--dark-bg-color);
  padding: .8rem;
  text-align: center;
  font-weight: 700;
}

.bf-price-tag p {
  font-size: 1em;
  padding: .8rem;
  text-align: center;
  margin-bottom: 0;
  font-weight: 500;
}

/* REVIEWS LIST */
ul.bf-reviews {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;

}

.bf-reviews-item {
  list-style: none;
  border: var(--dark-bg-color) solid 2px;
  border-radius: 6px;
  flex: 0 0 45%;
  max-width: 45%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {

  .bf-reviews-item {
    flex: 0 0 90%;
    max-width: 90%;
  }
}

.bf-reviews-item-header {
  color: var(--bg-color);
  background-color: var(--dark-bg-color);
  padding: .8rem;
}

.bf-reviews-item p {
  font-size: 1em;
  padding: .8rem;
  margin-bottom: 0;
}
.modal {
  display: flex;
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  text-align: justify;
  margin: auto;
  padding: 1rem;
  border: 1px solid #888;
  width: max(25rem, 60%);
}
.modal-content h3 {
  text-align: center;
}

.close {
  position: absolute;
  right: 2rem;
  top: 2rem;
  color: #212529;
  font-size: 28px;
  font-weight: bold;
  opacity: 1;
}

.close:hover,
.close:focus {
  color: #313334;
  text-decoration: none;
  cursor: pointer;
}


@-webkit-keyframes fade {
  from {
    opacity: .4
  }

  to {
    opacity: 1
  }
}

@keyframes fade {
  from {
    opacity: .4
  }

  to {
    opacity: 1
  }
}

@keyframes text-flicker-primary {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  2% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  9% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  12% {
    opacity: 0.1;
    text-shadow: 0px 0px var(--primary-color);
  }

  20% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color)
  }

  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 5px var(--primary-color)
  }

  30% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color)
  }

  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 5px var(--primary-color)
  }

  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 5px var(--primary-color)
  }

  77% {
    opacity: .9;
    text-shadow: 0px 0px 5px var(--primary-color)
  }

  100% {
    opacity: .9;
    text-shadow: 0px 0px 5px var(--primary-color)
  }
}

@keyframes text-flicker-secondary {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  2% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  8% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  9% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  12% {
    opacity: 0.1;
    text-shadow: 0px 0px var(--secondary-color);
  }

  20% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color)
  }

  25% {
    opacity: 0.3;
    text-shadow: 0px 0px 5px var(--secondary-color)
  }

  30% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color)
  }

  70% {
    opacity: 0.7;
    text-shadow: 0px 0px 5px var(--secondary-color)
  }

  72% {
    opacity: 0.2;
    text-shadow: 0px 0px 5px var(--secondary-color)
  }

  77% {
    opacity: .9;
    text-shadow: 0px 0px 5px var(--secondary-color)
  }

  100% {
    opacity: .9;
    text-shadow: 0px 0px 5px var(--secondary-color)
  }
}

@keyframes letter-flicker-primary {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  2% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  4% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }


  19% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  21% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  23% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  80% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  83% {
    opacity: 0.4;
    text-shadow: 0px 0px 5px var(--primary-color);
  }

  87% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--primary-color);
  }
}

@keyframes letter-flicker-secondary {
  0% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  2% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  4% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }


  19% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  21% {
    opacity: 0.1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  23% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  80% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  83% {
    opacity: 0.4;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }

  87% {
    opacity: 1;
    text-shadow: 0px 0px 5px var(--secondary-color);
  }
}

@keyframes bounceDown {
  0% {
    transform: translateY(0%) translateX(0%) scaleX(1) scaleY(1);
  }

  10% {
    transform: translateY(0%) translateX(0%) scaleX(1) scaleY(1);
  }

  15% {
    transform: translateY(10%) translateX(5%) scaleX(1.1) scaleY(0.8);
  }

  25% {
    transform: translateY(-100%) translateX(-5%) scaleX(1) scaleY(1);
  }

  65% {
    transform: translateY(-100%) translateX(-5%) scaleX(1) scaleY(1);
  }

  75% {
    transform: translateY(10%) translateX(15%) scaleX(0.8) scaleY(1.2);
  }

  85% {
    transform: translateY(-10%) translateX(0%) scaleX(1.2) scaleY(0.8);
  }

  95% {
    transform: translateY(5%) translateX(0%) scaleX(0.9) scaleY(1.1);
  }

  100% {
    transform: translateY(0) translateX(0%) scaleX(1) scaleY(1);
  }
}

@keyframes flip {
  0% {
    transform: rotate(0);
  }

  10% {
    transform: rotate(5deg);
  }

  45% {
    transform: rotate(-15deg);
  }

  100% {
    transform: rotate(0);
  }
}